.locked-tab {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 20px;

  &.has-bg {
    height: 300px;
    background: var(--primary-main);
  }

  .unlock-content {
    font-family: var(--font-dm-sans-medium);
    font-size: 18px;
    color: var(--text-secondary-main);
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 8px;
  }

  .not-available {
    font-size: 15px;
    color: var(--text-secondary-normal);
    margin: 24px 0;
    max-width: 500px;
    white-space: pre-line;
    text-align: center;
  }

  .btn-upgrade {
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #1EF7DD;
    background: var(--tab-active-gradient);
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 178px;
    height: 40px;
    font-family: var(--font-dm-sans-medium);
    font-size: 16px;
    color: var(--color-white);

    path {
      fill: var(--color-white);
    }
  }

  .btn-upgrade-new {
    position: relative;
    cursor: pointer;
    border-radius: 8px;
    padding: 12px 60px;
    font-family: var(--font-dm-sans-medium);
    font-size: 16px;
    line-height: 24px;
    color: var(--color-white);
    background: var(--primary-dark);

    &:before {
      z-index: -1;
      content: "";
      position: absolute;
      border-radius: 8px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      top: -5px;
      background: linear-gradient(129deg, #1ef7dd 27.79%, #6553fb 89.01%);
    }
  }
}