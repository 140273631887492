@media only screen and (max-width: 1200px) {
  .main-header-container {
    --header-height: 72px;
  }
}

.main-header-container {
  width: 100%;
  background-color: var(--primary-light);
  position: fixed;
  top: var(--notification-height);
  left: 0;
  right: 0;
  z-index: 998;
  height: var(--header-height);

  .mobile {
    .header-container {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 16px;

      .btn-logo {
        margin-left: 16px;
      }

      .btn-search {
        margin-left: auto;
        margin-right: 12px;
      }
    }
  }

  .tablet-desktop {
    display: flex;
    height: 100%;

    .header-container {
      width: 70%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 16px;

      .btn-logo {
        margin-left: 16px;
      }
    }

    .desktop-container {
      display: flex;
      width: 70%;
      height: 100%;
      align-items: center;
      padding-right: 24px;
    }
  }

  .header-left-content-container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .logo {
    width: 280px;
    height: 32px;
    padding-left: 24px;
    position: relative;

    .beta-logo {
      .logo-text {
        fill: var(--text-logo);
      }
    }
  }

  .search-bar-container {
    flex: 1;
    padding: 2px 2px 2px 40px;

    @media only screen and (max-width: 768px) {
      width: 100%;
      height: calc(100vh - var(--header-height));
      overflow-y: auto;
      padding: 24px 16px;
      background: var(--primary-dark);
    }
  }

  .btn-connect {
    position: relative;
    padding: 4px 12px 4px 16px;
    height: 36px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-family: var(--font-dm-sans-medium);
    color: var(--color-white);
    background: radial-gradient(
      145.91% 145.91% at 112.5% -6.25%,
      #0047ff 0%,
      #000 69.33%
    );
    border-radius: 18px;
    line-height: 36px;

    &:before {
      z-index: -1;
      content: "";
      position: absolute;
      border-radius: 18px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      top: -1px;
      background: linear-gradient(129deg, #1ef7dd 27.79%, #6553fb 89.01%);
    }

    svg {
      height: 20px;
      width: 20px;
    }
  }

  .menu-container {
    min-width: 500px;
    justify-content: end;
    display: grid;
    padding-right: 30px;
    grid-auto-flow: column;
    gap: 45px;
    align-items: center;
    margin-left: auto;
    list-style-type: none;
    text-decoration: none;

    a {
      text-decoration: none;

      &.disabled {
        pointer-events: none;
        color: var(--color-gray);
      }
    }

    .pro:before {
      right: -30px;
    }

    .text-secondary-normal {
      color: var(--text-secondary-normal);
      padding-right: 4px;
      cursor: pointer;
    }

    .profile-menu-container {
      .dropdown-menu {
        .MuiPaper-root {
          background: var(--secondary-main);
          border-radius: 10px !important;

          &::before {
            background: var(--secondary-main) !important;
          }
        }

        .MuiList-root {
          padding-top: 18px;
          padding-bottom: 10px;
        }

        .item {
          display: flex;
          align-items: center;
          padding-left: 27px;
          padding-right: 21px;
          color: var(--text-primary-main);
        }

        .icon {
          display: flex;
          align-items: center;
          margin-right: 8px;

          .user-profile-icon {
            width: 24px;
            height: 24px;
          }
        }
      }

      .wallet-address {
        min-width: 200px;
        background-color: var(--secondary-dark);
        height: 48px;
        padding: 0 16px;
        border-radius: 12px;
        color: var(--text-primary-main);
        cursor: pointer;
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .button-connect-wallet {
      position: relative;
      width: 190px;
      height: 42px;
      border-radius: 25px;
      color: var(--text-secondary-main);
      text-align: center;
      font-size: 15px;
      font-style: normal;
      background: linear-gradient(180deg, #312d9c 0%, #000 100%);
      line-height: 42px;
      letter-spacing: 1px;

      &:before {
        border-radius: 25px;
        z-index: -1;
        content: "";
        left: -4px;
        right: -4px;
        top: -4px;
        bottom: -4px;
        position: absolute;
        background: linear-gradient(129deg, #1ef7dd 27.79%, #6553fb 89.01%);
      }
    }

    @media only screen and (max-width: 1200px) {
      min-width: unset;
    }
  }
}

.dynamic-shadow-dom-content {
  height: 40px !important;
  display: flex;
}

[data-theme="light"] {
  .main-header-container {
    .btn-connect {
      background: var(--tab-active-gradient);

      &:before {
        content: unset;
      }
    }
  }
}
