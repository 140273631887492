.token {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;

  .token-logo {
    position: relative;

    .MuiSkeleton-root {
      position: absolute;
      left: 0;
      top: 0;
    }

    img {
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      display: block;
    }

    .chain-logo {
      position: absolute;
      right: -3px;
      bottom: 0;
      width: 16px;
      height: 16px;
    }
  }

  .token-symbol {
    white-space: nowrap;
    font-family: var(--font-dm-sans-medium);
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    color: var(--text-primary-main);

    &.text-secondary-normal {
      color: var(--text-secondary-normal);
    }

    .token-name {
      font-size: 10px;
    }
  }
}
